import connector, { Connector } from 'Utils/connector';
import { sharedLinkDataSelector } from 'Webapp/shared/concepts/shared-link';
import ShareLanding from 'Webapp/shared/app/views/shared-link-landing.js';
import { usageTrackTapAppInstall } from 'Webapp/shared/app/redux/actions/usage-actions.js';

const ShareLinkLandingContainer = connector({
  selectors: {
    featureFlagABTestSetupComplete: ({
      app: { featureFlagABTestSetupComplete },
    }) => featureFlagABTestSetupComplete,
    sharedLinkData: sharedLinkDataSelector,
  },
  actions: {
    usageTrackTapAppInstall,
  },
} as Connector)(ShareLanding);

export default ShareLinkLandingContainer;
