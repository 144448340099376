import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { TEXT_COLORS, SURFACE_COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { UI_HEADING_TYPES, BODY_TYPES } from 'Style/typography';
import { BREAKPOINTS } from 'Style/breakpoints';

// Utils
import { AppTheme } from 'Webapp/enums';
import { USAGE_NAV_FROMS, USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import getWindow from 'Utils/get-window';
import { euc, duc } from 'Utils/url';

// Components
import LoadingSpinner from 'ComponentLibrary/icons/loading-spinner';
import FlipboardLandscape from 'ComponentLibrary/logos/flipboard-landscape';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import AuthorAvatar from 'Webapp/shared/app/components/attribution/author-avatar';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectUsageSetNavFrom from 'Webapp/shared/app/connectors/connectUsageSetNavFrom';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';

const AVATAR_SIZE_PHONE = 96;
const AVATAR_SIZE_TABLET = 128;

const LandingWrapper = styled.div(
  {
    display: 'grid',
    gridTemplateRows: 'auto minmax(0, 1fr) auto',
    position: 'fixed',
    height: '100%',
    width: '100%',
    padding: SPACING.XLARGE,
    backgroundColor: SURFACE_COLORS.quaternary,
    color: TEXT_COLORS.overlay,
  },
  BREAKPOINTS.tablet({ padding: `${SPACING.BASE6X} ${SPACING.BASE4X}` }),
);
const Logo = styled(FlipboardLandscape)({
  margin: '0px',
  color: TEXT_COLORS.overlay,
});
const Content = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: SPACING.LARGE,
  },
  BREAKPOINTS.tablet({
    margin: '0 172px',
    flexDirection: 'row',
    alignItems: 'center',
  }),
);
const ContentBody = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: SPACING.LARGE,
});
const Avatar = styled(AuthorAvatar)(
  { minWidth: `${AVATAR_SIZE_PHONE}px` },
  BREAKPOINTS.tablet({ minWidth: `${AVATAR_SIZE_TABLET}px` }),
);

const Title = styled.span(UI_HEADING_TYPES.XLARGE, {
  color: TEXT_COLORS.overlay,
});
const Description = styled.span(BODY_TYPES.LARGE_STANDARD, {
  color: TEXT_COLORS.overlay,
});
const Footer = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: SPACING.XLARGE,
    alignItems: 'center',
    color: TEXT_COLORS.overlay,
  },
  BREAKPOINTS.tablet({ margin: '0 172px' }),
);

const DownloadButton = styled(Button)(
  {
    marginBottom: SPACING.BASE4X,
    width: '100%',
  },
  BREAKPOINTS.tablet({ padding: `${SPACING.XLARGE} 0` }),
);

class ShareLanding extends Component {
  async componentDidMount() {
    this.props.usageSetNavFrom(
      USAGE_EVENT_NAMES.SECTION_ENTER,
      USAGE_NAV_FROMS.INVITATION,
    );
    this.handleSharedLink();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.featureFlagABTestSetupComplete !==
        this.props.featureFlagABTestSetupComplete &&
      this.props.featureFlagABTestSetupComplete
    ) {
      this.handleSharedLink();
    }
  }

  shouldRedirect = () => {
    const {
      featureFlags,
      isAuthenticated,
      isPhone,
      isTablet,
      featureFlagABTestSetupComplete,
    } = this.props;
    if (isAuthenticated || !featureFlagABTestSetupComplete) {
      return true;
    }
    if (featureFlags.BRANCH_LANDING && (isPhone || isTablet)) {
      return false;
    }
    return true;
  };

  handleSharedLink = async () => {
    const { sharedLinkData, featureFlagABTestSetupComplete } = this.props;
    if (!featureFlagABTestSetupComplete) {
      return;
    }
    if (
      !sharedLinkData ||
      !sharedLinkData?.remoteId ||
      !sharedLinkData?.referringUser
    ) {
      getWindow().location = '/';
    }

    if (this.shouldRedirect()) {
      const redirectUrl = `/section/${euc(duc(sharedLinkData.remoteId))}`;

      getWindow().location = redirectUrl;
    }
  };

  render() {
    const { isPhone, usageTrackTapAppInstall, t, sharedLinkData } = this.props;
    const { sectionTitle, referringUser, downloadUrl } = sharedLinkData || {};
    const shouldRedirect = this.shouldRedirect();
    return (
      <LandingWrapper>
        <Logo height={30} appTheme={AppTheme.DARK} />
        <Content>
          {!shouldRedirect ? (
            <React.Fragment>
              <Avatar
                author={referringUser}
                avatarSize={isPhone ? AVATAR_SIZE_PHONE : AVATAR_SIZE_TABLET}
              />
              <ContentBody>
                <Title>
                  {t('invite_friend_landing_title', {
                    userName: referringUser.title,
                    sectionTitle,
                  })}
                </Title>
                <Description>
                  {t('invite_friend_landing_description', {
                    sectionTitle,
                  })}
                </Description>
              </ContentBody>
            </React.Fragment>
          ) : (
            <LoadingSpinner />
          )}
        </Content>
        {sectionTitle && !shouldRedirect && (
          <Footer>
            <DownloadButton
              name="download-app"
              styleVariation={StyleVariations.WHITE_OVERLAY}
              href={downloadUrl}
              onClick={() => usageTrackTapAppInstall('invitation')}
            >
              {t('invite_friend_download')}
            </DownloadButton>
            {t('invite_friend_explore', { sectionTitle })}
          </Footer>
        )}
      </LandingWrapper>
    );
  }
}

ShareLanding.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isPhone: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  featureFlagABTestSetupComplete: PropTypes.bool.isRequired,
  sharedLinkData: PropTypes.object,
  usageSetNavFrom: PropTypes.func.isRequired,
  usageTrackTapAppInstall: PropTypes.func.isRequired,
  featureFlags: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default connector(
  connectAuthentication,
  connectResponsive,
  connectUsageSetNavFrom,
  connectFeatureFlags,
)(withT(ShareLanding));
